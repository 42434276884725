import React, { useEffect, Fragment } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { startCase, lowerCase } from 'lodash';
import RepositCard from '../../components/Reposit/RepositCard';

import { Caption, Header4 } from '../../components/Typography';
import { Table, Tbody, Tr, Th, Td } from '../../components/Table';
import { Header2 } from '../../components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserByIdRequested,
  FETCH_USER_BY_ID_STORE_KEY,
  RESET_USER_PASSWORD_STORE_KEY,
  fetchOrganizationUsersByUserIdRequested,
  setIsEditModalOpen,
  UPDATE_USER_BY_ID_STORE_KEY,
} from '../../redux/user/user.actions';
import { AppState } from '../../redux/root.reducer';
import { getUserById } from '../../redux/selectors/user.selectors';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { FullPageLoader } from '../../components/Loading';
import moment from 'moment';
import Button from '../../components/Button';
import { setCurrentModal } from '../../redux/modal/modal.actions';
import { ResetPasswordModal } from '../../components/ResetPasswordModal';
import { getCurrentModal } from '../../redux/modal/modal.selectors';
import { UserDTOUserTypeIdEnum } from '@reposit/api-client';
import { getOrganizationUsersByUserId, getIsEditModalOpen } from '../../redux/user/user.selectors';
import { getBreakpoint } from '../../base/style';
import { NavLink } from 'react-router-dom';
import { EditUserModal } from './edit-user.modal';
import { useFlashMessage } from '../FlashMessage';
import FlashMessage from '../../components/FlashMessage';

const RESET_PASSWORD_EMAIL_MODAL_TYPE = 'RESET_PASSWORD_EMAIL';

const RESETTABLE_PASSWORD_USER_TYPES = [
  UserDTOUserTypeIdEnum.TENANT,
  UserDTOUserTypeIdEnum.AGENT,
  UserDTOUserTypeIdEnum.LANDLORD,
];

interface UserViewProps {
  location: any;
  match: any;
}

const UserDetails = styled(RepositCard)`
  width: 100%;
`;

const renderTd = (text: string) => <Td style={{ paddingLeft: 10 }}>{text}</Td>;

const OrgListWrapper = styled.ul`
  background: #fff;
  margin: 22px 0 36px;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

const OrgItem = styled.div`
  background: ${(props) => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 0 34px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.bgAccent};
  }
`;

const OrgItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  margin: 0 0 6px;
  padding: 12px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 48px;
    margin: 0 0 6px;
    padding: 0;
  }
`;

const Org = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const Name = styled(Header4)`
  font-size: 1em;
  margin: 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 10px 0 0;
  }
`;

const ViewLink = styled(NavLink)`
  margin: 0 0 0 auto;
`;

const ActionButton = styled(Button)`
  margin-left: auto;
`;

const UserView: React.FC<UserViewProps> = ({ match }) => {
  const dispatch = useDispatch();
  const userId = match.params.userId;
  const user = useSelector((state: AppState) => getUserById(state, { userId }));
  const orgUsers = useSelector((state: AppState) => getOrganizationUsersByUserId(state, userId));
  const fetchUserLoadingSelector = createLoadingSelector([FETCH_USER_BY_ID_STORE_KEY]);
  const isLoading = useSelector(fetchUserLoadingSelector);
  const fetchResetPasswordLoadingSelector = createLoadingSelector([RESET_USER_PASSWORD_STORE_KEY]);
  const isResetPasswordLoading = useSelector(fetchResetPasswordLoadingSelector);
  const currentModal = useSelector(getCurrentModal);
  useEffect(() => {
    dispatch(fetchUserByIdRequested(userId));
    dispatch(fetchOrganizationUsersByUserIdRequested({ userId }));
  }, [dispatch, userId]);
  const isEditModalOpen = useSelector(getIsEditModalOpen);
  const [flashMessage, onDismissFlashMessage] = useFlashMessage([UPDATE_USER_BY_ID_STORE_KEY]);
  const isTenant = !!(user && user.userTypeId === UserDTOUserTypeIdEnum.TENANT);

  if (isLoading || !user) return <FullPageLoader />;

  const { id, firstName, email, userTypeId, lastName, createdAt, attributes, legacyId } = user;
  let mobileNumber;
  let officePhoneNumber;
  let endOfTenancyEmailsDisabled;
  let endOfTenancyContact;
  let jobTitle;
  let dob;
  let gender;

  if (attributes) {
    mobileNumber = attributes.mobileNumber;
    officePhoneNumber = attributes.officePhoneNumber;
    endOfTenancyEmailsDisabled = attributes.endOfTenancyEmailsDisabled;
    endOfTenancyContact = attributes.endOfTenancyContact === undefined ? true : !!attributes.endOfTenancyContact;
    jobTitle = attributes.jobTitle;
    dob = attributes.dob;
    gender = attributes.gender;
  }

  const formatGender = (gender: string) => {
    if (gender) {
      return gender === 'UNKNOWN' ? 'Rather not say' : gender;
    }
  };

  return (
    <Fragment>
      {isEditModalOpen ? <EditUserModal user={user} /> : null}
      {currentModal === RESET_PASSWORD_EMAIL_MODAL_TYPE ? (
        <ResetPasswordModal
          onDismiss={() => dispatch(setCurrentModal(''))}
          email={email}
          userId={id}
          isLoading={isResetPasswordLoading}
        />
      ) : null}
      <Container>
        <Row>
          <Col lg={12}>
            <Header2>User #{id}</Header2>
            <Caption>{email}</Caption>
            {RESETTABLE_PASSWORD_USER_TYPES.filter((type) => type === userTypeId).length ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
                <Button onClick={() => dispatch(setCurrentModal(RESET_PASSWORD_EMAIL_MODAL_TYPE))}>Reset password</Button>
              </div>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <UserDetails
              flush
              title="User details"
              headerContent={
                userTypeId !== UserDTOUserTypeIdEnum.INTERNAL ? (
                  <ActionButton onClick={() => dispatch(setIsEditModalOpen(true))} buttonType={'secondary'}>
                    Edit
                  </ActionButton>
                ) : undefined
              }
              flashMessage={
                flashMessage ? (
                  <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={5000} payload={flashMessage} />
                ) : undefined
              }
            >
              <Container>
                <Row>
                  <Col lg={12} style={{ padding: 0 }}>
                    <Table>
                      <Tbody>
                        <Tr>
                          <Th width={240}>Id</Th>
                          {renderTd(id)}
                        </Tr>
                        <Tr>
                          <Th width={240}>Type</Th>
                          {renderTd(userTypeId)}
                        </Tr>

                        <Tr>
                          <Th>First name</Th>
                          {renderTd(firstName || '-')}
                        </Tr>
                        <Tr>
                          <Th>Last Name</Th>
                          {renderTd(lastName || '-')}
                        </Tr>
                        <Tr>
                          <Th>Email</Th>
                          {renderTd(email)}
                        </Tr>
                        {!isTenant ? (
                          <Tr>
                            <Th>Job Title</Th>
                            {renderTd(jobTitle || '-')}
                          </Tr>
                        ) : null}

                        <Tr>
                          <Th>Mobile Number</Th>
                          {renderTd(mobileNumber || '-')}
                        </Tr>
                        {!isTenant ? (
                          <Tr>
                            <Th>Office Phone Number</Th>
                            {renderTd(officePhoneNumber || '-')}
                          </Tr>
                        ) : null}

                        {legacyId && (
                          <Tr>
                            <Th>Legacy Id</Th>
                            {renderTd(legacyId)}
                          </Tr>
                        )}
                        {!isTenant ? (
                          <Tr>
                            <Th>Only subscribed to personal notifications</Th>
                            {renderTd(endOfTenancyContact ? 'False' : 'True')}
                          </Tr>
                        ) : null}
                        {!isTenant ? (
                          <Tr>
                            <Th>End Of Tenancy Notifications Disabled</Th>
                            {renderTd(endOfTenancyEmailsDisabled ? 'True' : 'False')}
                          </Tr>
                        ) : null}
                        {isTenant ? (
                          <Tr>
                            <Th>Date of Birth</Th>
                            {renderTd(dob ? moment(dob, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-')}
                          </Tr>
                        ) : null}
                        {isTenant ? (
                          <Tr>
                            <Th>Gender</Th>
                            {renderTd(gender ? startCase(lowerCase(formatGender(gender))) : '-')}
                          </Tr>
                        ) : null}
                        <Tr>
                          <Th>Registered Since</Th>
                          {renderTd(moment(createdAt).format('DD/MM/YYYY'))}
                        </Tr>
                      </Tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </UserDetails>
          </Col>
        </Row>
        {orgUsers && orgUsers.length ? (
          <Row>
            <Col>
              <UserDetails title="Organisation(s)">
                <OrgListWrapper>
                  {orgUsers.map((ou) => (
                    <OrgItem key={ou.id}>
                      <OrgItemInner>
                        <Org>
                          <Name>{ou.organization.name}</Name>
                        </Org>
                        <ViewLink to={`/organisations/${ou.organizationId}`}>
                          <Button buttonType="secondary" mini="true">
                            View
                          </Button>
                        </ViewLink>
                      </OrgItemInner>
                    </OrgItem>
                  ))}
                </OrgListWrapper>
              </UserDetails>
            </Col>
          </Row>
        ) : null}
      </Container>
    </Fragment>
  );
};

export default UserView;
